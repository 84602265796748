import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white, gray } from 'src/styles/colors'

export const Section = styled.div`

  .bread-crumb span {
    color: ${gray['700']} !important;
    font-weight: 300;
    opacity: 0.7;

    a {
      color: ${gray['700']};
      font-weight: 600;
      opacity: 0.9;
      
      &:hover {
        opacity: 0.5;
      }
    }
  }
`

export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`

export const SpanButton = styled.span`
  width: 100%;
  height: 46px;
  
  @media ${device.tablet} {
    width: 260px;
  } 

  @media ${device.desktopLG} {
    width: 360px;
  } 

  @media ${device.desktopXL} {
    width: 426px;
  } 
`
