import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import useWidthResize from 'src/hooks/window/useWidth'
import Layout from 'src/layouts/BaseLayout'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Link } from 'gatsby'
import OpenModal from 'src/components/OpenModal'
import { FixedIncome } from 'src/components/InvestmentSimulators'
import InvestmentsTab from 'src/components/InvestmentsTab'
import FAQ from 'src/components/Faq'
import BlogArticles from 'src/components/BlogArticles'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import QrCode from './assets/images/qr_tesouro.png'
import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { Section, FAQSection } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import headerJson from './assets/data/header.json'
import { Modal } from 'src/components/Modal'
import Popup from 'src/components/Popup'
import useDomReady from 'src/hooks/window/useDomReady'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import { WIDTH_XL } from 'src/styles/breakpoints'
import { TesouroPopup } from '../../PopUpTesouro/_exit-popup-tesouro'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'

function RendaFixaCDB () {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const windowWidth = useWidthResize(200)
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)
  const [ popup, setPopup ] = useSessionStorage('@hide-inter-popup-investimentos-tesouro-direto', false)
  const isDesktop = windowWidth > WIDTH_XL

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup closeModal={() => setIsPopupOpen(false)} setPopup={setPopup} pageName='investments' child={<TesouroPopup />} />
    </Modal>
  )

  const dataLayerQR: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Tesouro Direto: O investimento mais seguro do país',
    element_action: 'click button',
    element_name: 'Quero conhecer',
  }

  const OpenAccountModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={QrCode}
        dataLayer={dataLayerQR}
        agoraBaixeOAppLink='https://intergo.app/b28d5932'
        deepLink='https://intergo.app/b28d5932'
      />
    </Modal>
  )

  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  return (
    <Layout pageContext={pageContext}>
      {PopupExitPage}
      {OpenAccountModal}
      <Section className='pt-4'>
        <div className='container'>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-6 offset-md-1 offset-lg-2 col-lg-5 order-md-last'>
              <Img
                fluid={data.imgHero.fluid}
                alt='Tela do Super App da Inter Invest mostrando como investir em renda fixa.'
                className='float-lg-right'
              />
            </div>
            <div className='col-12 col-md-5 pr-md-0'>
              <div className='mb-3'>
                <div className='bread'>
                  <Link
                    to='/'
                    className='d-md-inline'
                  ><Home height={24} width={24} color='#6A6E81' />
                  </Link>
                  <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                  <Link
                    to='/pra-voce/investimentos/'
                    className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                  >
                    {headerJson.breadcrumb[0]}
                  </Link>
                  <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                  <Link
                    to='/pra-voce/investimentos/renda-fixa/'
                    className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mr-2'
                  >
                    {headerJson.breadcrumb[1]}
                  </Link>
                  <OrangeIcon icon='arrow-right' color='#161616' size='SM' className='ml-2' />
                  <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[2]}</p>
                </div>
              </div>
              <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-4 text-grayscale--500 fw-600 font-sora'>
                Tesouro Direto: <span className='d-block'>O investimento mais</span> seguro do país
              </h1>
              <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--400 mb-4'>
                Comece a investir com pouco para garantir um <span className='d-xl-block'> futuro mais tranquilo e seguro.</span>
              </p>
              <div className='mt-2 mt-4 mb-5'>
                <a
                  style={{ paddingTop: 13 }}
                  title='Quero conhecer'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-block d-md-none'
                  href='https://intergo.app/b28d5932'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Investimentos que simplificam sua vida',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                        redirect_url: 'https://intergo.app/b28d5932',
                      })
                  }}
                > Quero conhecer
                </a>
                <button
                  title='Quero conhecer'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-none d-md-block'
                  onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Tesouro Direto: O investimento mais seguro do país',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                      })
                  }}
                >
                  Quero conhecer
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section id='outros-investimentos' className='pt-md-5 pb-md-5'>
        <div className='container pb-lg-3'>
          <h2 className='fs-28 fs-md-42 mb-4 mt-2 text-grayscale--500 text-md-center font-sora'>
            Segurança para investir a longo prazo
          </h2>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 px-0'>
              <p className='fs-16 text-md-center text-grayscale--400'>
                Confira a simulação e veja como seu dinheiro pode render.
              </p>
            </div>
          </div>
          <div className='pt-4 d-md-none d-lg-none'>
            <h3 className='fs-17 text-grayscale--500 font-sora'>
              Veja um exemplo de como o seu dinheiro pode render.
            </h3>
          </div>
          <div className='shadow mt-4 mb-3 rounded-4 p-1 p-md-3'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-4'>
                <div className='box-content mt-0 mt-lg-5 mb-4'>
                  <p className='fw-700 mb-1 text-grayscale--500 font-sora'>
                    Considerações utilizadas nos exemplos:
                  </p>
                  <ul>
                    <li>Títulos Isentos de IR (LCI DI e Poupança) estão com taxas Gross-Up (consideramos a taxa como se o investimento fosse sujeito a IR, como um CDB). Assim, todos os valores demonstrados são brutos.</li>
                    <li>A expectativa é de que a <a href='https://blog.inter.co/taxa-selic-hoje'>taxa Selic</a> oscile durante o período, do contrário, a rentabilidade poderá ser afetada.</li>
                    <li>Considera-se que o título do Tesouro Direto é a LFT (Tesouro Selic) e que sua rentabilidade é de 100% do CDI.</li>
                  </ul>
                </div>
                <div>
                  <button
                    title='Comece a investir agora'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        section_name: 'Segurança para investir a longo prazo',
                        element_action: 'click button',
                        element_name: 'Comece a investir agora',
                      })
                    }}
                  >
                    Comece a investir agora
                  </button>
                </div>
              </div>
              <div className='col-12 col-lg-8 px-0'>
                <FixedIncome products='tesouro,poupanca' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='outros-investimentos'>
        <div className='container pt-4 pb-md-5'>
          <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 font-sora mb-2 mb-md-4'>
            Veja outros tipos de investimentos
          </h2>
          <InvestmentsTab
            section='dobra_3'
            elementAction='click button'
            section_name='Veja outros tipos de investimentpos'
          />
        </div>
      </section>

      <section id='novidades-investimentos' className='py-md-4'>
        <div className='container'>
          <div className='col-12 mb-4'>
            <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center text-grayscale--500 font-sora mb-4'>
              Os melhores conteúdos sobre investimentos
            </h2>
            <p className='text-left mt-1 text-lg-center text-grayscale--400 mb-0'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <BlogArticles
            type='investments'
            section='dobra_4'
            elementAction='click button'
            elementName='Saiba mais'
            sectionName='Os melhores conteúdos sobre investimentos'
          />
        </div>
      </section>

      <FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-4 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h2 className='fs-28 fs-md-42 mb-4 text-md-center text-grayscale--500 font-sora'>
                Perguntas frequentes sobre o Tesouro Direto
              </h2>
              <p className='fs-16 text-md-center text-grayscale--400'>
                Tire suas dúvidas sobre o investimento no Tesouro Direto, que oferece diversas opções
                de títulos com diferentes prazos e rentabilidades.
              </p>
            </div>
            <FAQ
              className='summary-content'
              answerData={pageContext.structuredData.faq}
              searchBg='#ffffff'
            />
          </div>
        </div>
      </FAQSection>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_06'
      />
    </Layout>
  )
}

export default RendaFixaCDB
