import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerDesktop: imageSharp(fluid: {originalName: { regex: "/banner-renda-fixa-desktop/" }}) {
        fluid(maxWidth: 1722, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerMobile: imageSharp(fluid: {originalName: { regex: "/banner-renda-fixa-mobile/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgHero: imageSharp(fluid: {originalName: { regex: "/hero-tesouro-direto/" }}) {
        fluid(maxWidth: 567, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
